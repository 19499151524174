@import "scss/base.scss";
$arrow-height: 20px;

.gradientTextContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  .gradientText {
    display: flex;
    align-items: center;
    background: var(--blue-yellow-gradient);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    margin-right: $margin-2;
    font-size: $font-size-slightly-smallText;
  }

  .gradientTextArrow {
    height: $arrow-height;
    transform: rotate(180deg);
  }
}