@import 'scss/base.scss';
@import 'scss/base/sizes.scss';

$header-height: 50px;
$header-width: 100%;
$desktop-search-height: 32px;
$desktop-search-width: 20%;
$search-container-width: 50%;
$dropdown-row-height: 50px;
$dropdown-width: 360px;
$progress-bar-height: 16px;
$setting-modal-width: 90px;
$setting-modal-row-height: 23px;
$table-index: 500;
$table-top-offset: 45px;
$mobile-small: 436px;
$mobile-small-dropdown-width: 250px;
$notification-table-offset: -230px;

.header {
  background-color: $color-blue;
  display: flex;
  align-items: center;
  height: $header-height;
  width: $header-width;

  .container {
    display: flex;
    align-items: center;
    width: $full;
    justify-content: flex-end;

    .welcome {
      font-size: $font-size-large; 
      display: flex;
      align-items: center;
      flex: 1;
    }

    .searchContainer {
      display: flex;
      width: $desktop-search-width;
      margin-right: $margin-5;

      > div {
        margin-left: $margin-20;
        flex: 1;
      }

      .searchBar {
        background-color: $color-background-white-secondary;
        border: $hide;
        height: $desktop-search-height;
      }
    }

    .notification {
      position: relative;
      margin: $no-margin $margin-5;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .showAllNotifications{
      display: flex;
      justify-content: center;
      margin: $margin-5 $no-margin;
      padding: $padding-10 $no-padding $no-padding $no-padding;
      font-size: $font-size-smallerText;
      font-weight: bold;
      color: $color-blue;
      border-top: $border-size solid $color-light-grey;
      cursor: pointer;
    }

    .notificationList {
      @include dropShadow();
      position: absolute;
      top: $table-top-offset;
      right: 0;
      z-index: $table-index;
      display: flex;
      flex-direction: column;
      background-color: $color-background;
      border-radius: $border-radius-round-slight;
      padding: $padding-small;
  
      .row {
          display: flex;
          flex-direction: row;
          height: $dropdown-row-height;
          justify-content: start;
          align-items: center;
          padding-left: $padding-xsmall;
          padding-right: $padding-small;
          font-size: $font-size-slightly-smallText;
          border-radius: $border-radius-round-slight;
          cursor: pointer;
  
          &:hover {
              background-color: $color-background-hover;
          }
      }

      .removeButton {
        font-weight: bold;
        color: $color-black;
      }

      .removeButtonRead {
        font-weight: bold;
        color: $color-input-grey !important;
      }
    }

    .icon, .desktopIcon {
      margin: $no-margin $margin-5;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        circle { 
          fill : $color-background-hover-secondary;
        }
      }
    }

    .rightMostDesktopIcon {
      margin: $no-margin $margin-5;
      margin-right: $margin-10;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        circle { 
          fill : $color-background-hover-secondary;
        }
      }
    }

    .sidebar {
      display: $hide; 
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1000;
      height: $full;
    }

    .settingModal {
      width: $setting-modal-width;
      height: fit-content;
      margin: $margin-50 $margin-25 auto auto;
    
      // overriding modal.module.scss
      padding: $padding-3;
      border-color: $color-grey;
      background: $color-background;
    
      .modalContent {
        border: $hide;
        outline: $hide;
    
        .contentRow {
          height: $setting-modal-row-height;
          border-bottom: $border-size solid $color-grey;
          display: flex;
          position: relative;
          font-size: $font-size-smallerText;
          font-weight: $font-weight-normal;
          color: $color-text-subname;
          
          .labelContainer {
            position: absolute;
            width: $full;
            
            .label {
              margin-left: $margin-10;
            }
          }
          
          svg {
            position: absolute;
            right: 5px;
            top: 2px;
          }
          :nth-child(1) {
            order: 2;
          }
        }
        :last-child {
          border-bottom: none;
        }
        :active, :hover {
          background-color: transparent;
          .labelContainer {
            border-radius: $border-radius-round;
            background-color: $color-background-hover;
          }
        }
      }
    }
  }
}

// Desktop
@media only screen and (min-width: $tablet-max-breakpoint) {
  .notificationList {
    width: $dropdown-width;
  }
  .dropdown {
    display: $hide;
  }
  .mobileDesktopIcon {
    display: $hide;
  }
  .settingModal{
    display: $hide;
  }
  .coverModal {
    display: $hide;
  }
  .sidebar {
    visibility: hidden; 
  }
}

// Mobile & Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {
  .header {
    .dropdown {
      display: block;
      margin: $no-margin $margin-5;
      background-color: $color-input-white;
      padding: $padding-4;
      border-radius: $border-radius-round;
    }
    .container{
      .searchContainer {
        width: $search-container-width;
      }
      .desktopIcon{
        .mobileRemoval {
          display: $hide;
        }
      }
      .rightMostDesktopIcon {
        &.mobileRemoval {
          display: $hide;
        }
      }
    }
  }
  .mobileDesktopIcon {
    display: flex;
    margin-right: $margin-10;
  }
  .desktopIcon {
    display: $hide;
  }
  .hamburgerIcon {
    margin-left: $margin-10;
  }
}

// Mobile
@media only screen and (max-width: $mobile-small) {
  .notificationList{
    left: $notification-table-offset;
    width: $mobile-small-dropdown-width;
  }

}
