@import "scss/base.scss";

$tab-height: 30px;
$tab-border-radius: 12px 12px 0px 0px;


.tab {
    color: $color-text-disabled;
    height: $button-height;
    border-radius: $tab-border-radius;
    padding-left: $padding-15;
    padding-right: $padding-15;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: $font-weight-normal;
    font-size: $font-size-slightly-smallText;
    background: linear-gradient($color-background, $color-background) padding-box,
    linear-gradient(to right, $color-blue, $color-yellow) border-box;
    border: $border-size solid transparent;
    line-height: $line-height-small;

    &:not(.active) {
        &:hover {
            background: linear-gradient($color-background-hover-secondary, $color-background-hover-secondary) padding-box,
            linear-gradient(to right, $color-blue, $color-yellow) border-box;        }

        svg path {
            fill: $color-text-disabled;
        }
    }

    svg {
        margin-right: $margin-small;
    }
}

.active {
    color: $color-blue;
    font-weight: $font-weight-slightly-bold;
}


/* Media Queries */
// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {
}

// // Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {
}