@import "scss/base.scss";

.csvViewer {
    margin: 20px;
  
    .table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
  
        th, td {
            border: 1px solid $color-grey;
            text-align: left;
            padding: 8px;
        }
  
        th {
            background-color: $color-light-grey;
            font-weight: bold;
        }
  
        tr:nth-child(even) {
            background-color: $color-header-background-grey;
        }
    }
}
