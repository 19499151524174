@import "scss/base.scss";

$button-width: 22%;
$small-spacer: 1.5%;
$large-spacer: calc(100% - 3*$button-width - $small-spacer);
$mobile-button-pacer: 5px;
$document-modal-min-height: 30%;
$document-modal-height: 80%;
$document-modal-left: 10%;


.filterButtons {
    @include filterButtons();
}

.submitSection {
    display: flex;
    button {
        margin-left: $no-margin;
        margin-right: $no-margin;
        width: $button-width;
        font-size: $font-size-slightly-smallText;
    }
    .saveButton {
        margin-right: $large-spacer;
    }
    .rejectButton {
        margin-right: $small-spacer;
    }
}


.documentModal {
    width: $modal-width;
    height: $document-modal-height;
    min-height: $document-modal-min-height;
    margin-left: $document-modal-left;
    margin-top: $margin-50;

    h2 {
        font-size: $font-size-slightly-large;
    }

    img {
        width: $full;
        height: $full;
    }

    .modalContent {
        margin-left: $margin-10;
        height: 90%;
        overflow: auto;
        overflow-wrap: break-word;
        .contentRow {
            width: $full;
            border-bottom: $border-size solid $color-grey;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
  }



// TODO: When cleanup happens, this should be a shared class
.rowColumnOne {
    display: block;
    flex: 1;
    margin: $margin-5 $margin-5;
}

.rowColumnTwo {
    display: block;
    flex: 2;
    margin: $margin-5 $margin-5;
}

/* Media Queries */

// Desktop
@media only screen and (min-width: $tablet-max-breakpoint) {

}

// Mobile
@media (max-width: $tablet-max-breakpoint) {
    .filterButtons {
        flex-wrap: wrap;
        row-gap: $margin-10;
        
        .newAppButton, .filter, .sort {
            flex: 1;
        }
        .searchBar {
            width: $full;
        }
    }

    .submitSection {
        .saveButton {
            margin: $no-margin $mobile-button-pacer auto $no-margin; 
            width: $full;
        }
        .rejectButton {
            margin: $no-margin $mobile-button-pacer auto $mobile-button-pacer; 
            width: $full;
        }
        .approveButton {
            margin: $no-margin $no-margin auto $mobile-button-pacer; 
            width: $full;
        }
    }
}

