@import 'scss/base.scss';

$top-sidebard-height: 3.5rem;

.sidebar {
    width: $sidebar-width;
    min-width: $sidebar-width;
    height: $full;
    display: flex;
    flex-direction: column;
}

.topSidebar {
    width: calc($full - $margin-5 * 2);
    margin: $margin-30 $margin-5;
    display: flex;
    justify-content: center;
}

.companyLogo {
    height: $full;
    width: $full;
}



.sidebarOptionsWrapper{
    display: flex;
    flex-direction: column;
    height: $full;
}

.topSidebarButtons{
    flex: 1;
}
.logo {
    height: 5rem;
} 

.sideBarIcon{
    margin-left: $margin-large;
    margin-right: $margin-large;
}

.exitButton {
    margin: 0;
    margin-right: $margin-10;
    margin-top: $margin-5;
    text-align: right;
    color: $color-blue;
    cursor: pointer;
  }


/* Media Queries */
// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {
    .bottomSidebar {
        justify-content: flex-end;
    }
    .sidebar {
        background-color: $color-background-white-secondary;
    }
}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) {
    .exitButton{
        display: $hide;
    }
}

