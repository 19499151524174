@import "scss/base.scss";

$bubble-height: 16px;
$bubble-width: 50px;
$row-height: 60px;
$table-header-height: 30px;
$status-bubble-width: 115px;

.clientTable {
    width: $full;
    border-collapse: separate;
    border-spacing: 0 0;
    overflow-y: auto;
    border-spacing: $no-margin $margin-5;
    
    td {
        position: relative;
        overflow: hidden;
        background-clip: padding-box;
    }
}

.barRow {
    height: $border-size;
    background-color: $color-light-grey;

    td {
        padding: $no-padding;
        margin: $no-margin;
        height: $border-size;
        border: none;
        background-color: $color-light-grey;
    }
}

.selectableRow {
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: $color-background-hover;
    }
}

th {
    text-align: left;
    font-size: $font-size-slightly-smallText;
    font-weight: $font-weight-normal;
    color: $color-text-subname;

    &:first-child {
        padding-left: $padding-20;
    }

    &:last-child {
        padding-right: $padding-20;
    }
}

thead {
    tr {
        height: $table-header-height;
    }
}

tr {
    height: $row-height;
    font-size: $font-size-slightly-smallText;

    td {
        position: relative;

        &:first-child {
            padding-left: $padding-20;
        }

        &:last-child {
            padding-right: $padding-20;
        }
    }

    td:first-child {
        border-top-left-radius: $border-radius-round-mid;
        border-bottom-left-radius: $border-radius-round-mid;
    }

    td:last-child {
        border-top-right-radius: $border-radius-round-mid;
        border-bottom-right-radius: $border-radius-round-mid;
    }
}

.bubble {
    background-color: $color-blue;
    border-radius: $border-radius-round;
    color: $color-white;
    font-weight: $font-weight-slightly-bold;
    height: $bubble-height;
    font-size: $font-size-slightly-smallText;
    width: fit-content;
    min-width: $bubble-width;
    padding-left: $padding-12;
    padding-right: $padding-12;
    padding-top: $padding-6;
    padding-bottom: $padding-6;
    display: flex;
    align-items: center;
    justify-content: center;
}

.viewButton {
    margin: $no-margin;
    width: $full;
}
