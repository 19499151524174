@import "scss/base.scss";

$eye-icon-right: 20px;
$eye-icon-top: 40px;
$remember-me-icon-left: 25px;
$remember-me-icon-top: 1px;

.labels {
   width: $full;
   display: inline-block;
   font-size: $font-size-smallText;
}

.field {
    margin-right: $margin-small;
    width: $full;

    input {
        margin-bottom: $margin-10;
        border-color: transparent;
    
        &::placeholder {
            font-style: normal;
          }
    }
}


.submitButton {
    background-color: $color-submit-button-background;
    width: $full;
    border-radius: $border-radius-round;
    display: block;
    margin-top: $margin-20;
    margin: $margin-medium $no-margin;
    font-weight: $font-weight-bold;
}

.loginMiscButtons {
    margin-top: $margin-small;
    margin-bottom: $margin-large;
    position: relative;
    font-size: $font-size-smallText;

    label {
        position: absolute;
        left: $remember-me-icon-left;
        top: $remember-me-icon-top;
    }
}

.forgotPass {
    text-align: center;
    margin-top: $margin-30;
    cursor: pointer;

    .forgot {
        color: $color-blue;
        text-decoration: $hide;
    }
}

.loginButtonSection {
    display: flex;
}

.loginButton {
    width: $full;
    margin: auto;
    margin-top: $margin-medium;
}