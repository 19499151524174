@import "scss/base.scss";

$account-container-width: 70%;
$account-container-min-width: 600px;
$account-container-max-width: 1000px;
$save-button-width: 50%;
$save-button-max-width: 500px;
$input-height: 40px;
$input-label-title-width: 200px;
$tabs-gap: 2px;
$input-container: 0%;


.settings {
    @include pageHeader();
}

.tabs {
    display: flex;
    column-gap: $tabs-gap;
    margin-bottom: $margin-medium;
}

.settingsContentWrapper {
    @include pageContentWrapper();
    padding: $padding-40 $padding-30 $padding-40 $padding-30;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.account {
    display: flex;
    flex-direction: column;
    row-gap: $padding-30;
    overflow: auto;
    height: $full;

    .accountInputContainer {
        width: $full;
    }

    .saveButton {
        width: $full;
        max-width: $account-container-max-width;
        margin: $no-margin;
    }
}

.resetPassword {
    display: flex;
    overflow: hidden;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: $full;
    overflow: auto;
    width: $full;
    color: $color-text-black;
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {

    .account {
        display: flex;
        align-items: center;


        .accountInputContainer {
            width: $input-container;
            max-width: $account-container-max-width;
            min-width: $account-container-min-width;
            display: flex;
            flex-direction: row;
            align-items: center;
            border: $hide;
            padding-right: $padding-medium;
    
            
            .accountInput, .accountDropdown {
                width: $full;
                height: $input-height;
                padding-top: 0;
                background-color: $color-background-white-secondary;
                color: $color-black;
                border: $border-size solid $color-border;
                border-radius: $border-radius-round-slight;
            }
    
            .accountLabel {
                width: $input-label-title-width;
                position: static;
                font-size: $font-size-slightly-smallText;
            }

            input { 
                padding-left: $padding-5;
            }
    
            select {
                padding-bottom: $no-padding;
                align-items: center;
            }
        }
        .saveButton {
            width: $save-button-width;
            max-width: $save-button-max-width;
        }
    }
}

// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {
    .account {
        row-gap: $padding-20;
        padding-right: $padding-10;
    }

    .resetPassword {
        padding-right: $padding-10;
    }
}
