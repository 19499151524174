@import "scss/base.scss";

$account-container-width: 70%;
$account-container-min-width: 800px;
$account-container-max-width: 1000px;
$save-button-width: 35%;
$save-button-max-width: 500px;
$input-height: 40px;
$input-label-title-width: 200px;
$tabs-gap: 2px;
$input-container: 0%;

.account {
  display: flex;
  flex-direction: column;
  row-gap: $padding-10;
  overflow: auto;
  height: $full;

  .accountInputContainer {
    width: $full;
  }

  .accountInputWrapper {
    width: initial;
  }

  .saveButton {
    width: $full;
    max-width: $account-container-max-width;
    margin: $no-margin;
  }

  .alert {
    color: $color-alert;
    font-size: $font-size-smallText;
  }
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {
  .account {
    display: flex;
    align-items: center;

    .accountInputContainer {
      width: $input-container;
      max-width: $account-container-max-width;
      min-width: $account-container-min-width;
      display: flex;
      flex-direction: row;
      align-items: center;
      border: $hide;
      padding-right: $padding-medium;

      .accountInput,
      .accountDropdown {
        width: $full;
        height: $input-height;
        padding-top: $no-padding;
        background-color: $color-background-white-secondary;
        color: $color-black;
        border: $border-size solid $color-border;
        border-radius: $border-radius-round-slight;
      }

      .accountDropdown {
        padding-left: $padding-10;
      }

      .accountLabel {
        width: $input-label-title-width;
        position: static;
        font-size: $font-size-slightly-smallText;
        color: $color-text-black;
      }

      input {
        padding-left: $padding-15;
      }

      select {
        padding-bottom: $no-padding;
        align-items: center;
      }
    }
    .saveButton {
      width: $save-button-width;
      max-width: $save-button-max-width;
      background: linear-gradient($color-background, $color-background) padding-box,
        linear-gradient(to right, $color-blue, $color-yellow) border-box;
      border: $border-size solid transparent;
      border-radius: $border-radius-round;
      div {
        color: $color-text-black;
        font-weight: $font-weight-normal;
      }
    }
  }
}

// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {
  .account {
    row-gap: $padding-20;
    padding-right: $padding-10;
  }

  .resetPassword {
    padding-right: $padding-10;
  }
}
