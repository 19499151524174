@import "scss/base.scss";


.layoutParent {
    display: flex;
    flex-direction: row;
    height: $full;
    width: $full;
    overflow: hidden;
}

.rightContent {
    flex: auto;
    display: flex;
}