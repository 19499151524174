@import "scss/base.scss";

$button-min-height: 48px;
$secondary-min-height: 40px;
$upload-min-height: 40px;

.button {
  min-height: $button-min-height;
  border-radius: $border-radius-slightly-round;
  cursor: pointer;
  font-size: $font-size;
  padding: $no-padding $button-horizontal-padding;
  appearance: $hide;
  margin: $margin-medium;
  align-items: center;
  line-height: $line-height-small;

  &:disabled {
    cursor: not-allowed;
  }

  &.primary {
    font-weight: $font-weight-slightly-bold;
    background-color: $color-button-background;
    border-color: $color-button-background;
    color: $color-button-text;
    border: $hide;

    div, p, a, span {
      color: $color-button-text;
    }

    &:hover {
      background-color: rgba($color-button-background, 0.75);
      border-color: rgba($color-button-background, 0.75);
    }

    &:disabled {
      background-color: rgba($color-button-background, 0.5);
      border-color: rgba($color-button-background, 0.5);

      &:hover {
        background-color: rgba($color-button-background, 0.25);
        border-color: rgba($color-button-background, 0.25);
      }
    }
  }

  &.secondary {
    background-color: $color-input-white;
    font-size: $font-size-slightly-smallText;
    padding-left: $padding-15;
    padding-right: $padding-15;
    border-width: $border-size;
    border-style: solid;
    border-radius: $border-radius-round;
    border-color: $color-grey;
    min-height: $secondary-min-height;
    margin: $no-margin;

    &:hover {
      background-color: $color-background-hover;
    }

    &:disabled {
      background-color: rgba($color-button-background-disabled, 0.5);
      border-color: rgba($color-button-background-disabled, 0.5);
      &:hover {
        background-color: rgba($color-button-background-disabled, 0.25);
        border-color: rgba($color-button-background-disabled, 0.25);
      }
    }
  }

  &.gradient {
    position: relative;
    color: $color-black;
    background-color: $color-background;
    border: 1px solid; 
    border-radius: $border-radius-round;
    transition: background-color 0.3s;
    border: none;
    background: linear-gradient(to right, var(--color-blue), var(--color-yellow));
    cursor: pointer;
    outline: none;

    .innerGradientButtonBG {
      position: absolute;
      width: calc(100% - 2.3px); // .3 prevents overlapping pixels on rendering
      height: calc(100% - 2.3px);
      inset: 0;
      margin: auto;
      display: block;
      background-color: $color-stark-white;
      border-radius: inherit;
    }

    &:hover {
      .innerGradientButtonBG {
        background-color: $color-blue; 
      }

      .buttonLabel {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-stark-white;
      }
    }

    .disabledStyle {
      background: $color-white;
      pointer-events: none;

      .innerGradientButtonBG {
        color: rgba($color-black, 0.5);
        background-color: $color-white;
      }
    }

    .buttonLabel {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      pointer-events: none;
    }
  }

  &.upload {
    color: $color-white;
    background-color: $color-upload-button-background;
    font-size: $font-size-slightly-smallText;
    padding-left: $padding-15;
    padding-right: $padding-20;
    border-radius: $border-radius-round;
    min-height: $upload-min-height;
    border: $hide;
    margin: $no-margin;

    div, p, a, span {
      color: $color-button-text;
    }

    &:hover {
      background-color: $color-upload-button-hover;
    }
  }
}

.linkButton {
  font-size: $font-size;
  padding: $padding-small $padding-medium;
  text-decoration-line: $hide; 
}

.mobileOnly {
  display: $hide
}

.desktopOnly {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

// mobile only
@media only screen and (max-width: $tablet-min-breakpoint) {
  .mobileOnly {
    display: block;
  }
  .desktopOnly {
    display: $hide;
  }
}