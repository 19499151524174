@import "scss/base.scss";

$container-height: 32px;
$input-height: 32px;

.searchContainer {
  border-width: $border-size;
  border-radius: $border-radius-round;
  border-style: solid;
  border-color: $color-grey;
  padding-right: $padding-10;
  display: flex;
  align-items: center;
  height: $input-min-height-small;

  input {
    background-color: $color-input-white;
    width: $full;
    flex: 1;
    border: $hide;
    -webkit-appearance: $hide;
    height: $input-min-height-small;
    color: $color-black;
    padding-left: $padding-15;
    padding-right: $padding-5;
    background-color: transparent;

    &::placeholder {
      color: $color-input-placeholder;
      font-style: normal;
    }
    &:focus {
      outline: $hide;
    }
  }

  .search {
    display: flex;
    align-items: center;
  }
}

.searchMobile {
  display: $hide;
}

/* Media Queries */
// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {
  .searchMobile {
    display: flex;
    align-items: center;
    margin-left: $margin-10;
  }
  .positionLeft .search {
    visibility: hidden;
  }
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {
}
