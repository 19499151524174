@import "./base/colors.scss";
@import "./base/sizes.scss";
@import "./base/grid.scss";
@import "./base/media-queries.scss";
@import "./fonts.scss";
@import "./base/mixins.scss";
@import "./datePickerOverwrite.scss";

code {
  white-space: pre-line;
}

li {
  margin-bottom: $margin-medium;
}

.codeContainer {
  background-color: $color-white;
  border-radius: 10px;
  padding: $padding-large;
}

.sideBySide {
  display: flex;
  .section {
    flex: 1;
    margin: $margin-small;
  }
}

.barDiv{
  width: $full;
  height: $border-size;
  background-color: $color-light-grey;
}

.emphasize {
  font-weight: bold;
  color: $color-blue;
}

.hide {
  display: $hide;
}

.greyed-out {
  color: $color-text-disabled !important;
}

.welcome {
  display: flex;

  .welcomeText {
      margin-bottom: $margin-5;
      font-weight: $font-weight-bolder;
      font-size: $font-size-larger;
      background: linear-gradient(to right, $color-blue, $color-yellow);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent; 
  }
}

.row {
  display: flex;
  flex-direction: row;
}

p, span, h1, h2, h3,
h4, h5, h6 {
  
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: $font-size-h1;
}

h2 {
  font-size: $font-size-h2;
  font-weight: bold;
}

h3 {
  font-size: $font-size-h3;
}

h4 {
  font-size: $font-size-h4;
}

h5 {
  font-size: $font-size-h5;
}

* {
  font-family: 'Poppins';
}

br {
  height: $line-break-height;
}


.outlineMode * { outline: 2px solid rgba(255,0,0,.6); :hover { outline: 5px solid rgba(255,0,0,0.6); } }
.outlineMode * * { outline: 2px solid rgba(0,255,0,.6); :hover { outline: 5px solid rgba(0,255,0,0.6); } }
.outlineMode * * * { outline: 2px solid rgba(0,0,255,.6); :hover { outline: 5px solid rgba(0,0,255,0.6); } }
.outlineMode * * * * { outline: 2px solid rgba(255,0,255,.6); :hover { outline: 5px solid rgba(255,0,255,0.6); } }
.outlineMode * * * * * { outline: 2px solid rgba(0,255,255,.6); :hover { outline: 5px solid rgba(0,255,255,0.6); } }
.outlineMode * * * * * * { outline: 2px solid rgba(255, 251, 4, 0.6); :hover { outline: 5px solid rgba(255,251,4,0.6); } }




// Scrollbar

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: $border-radius-round;
}

/* Track */
::-webkit-scrollbar-track {
  background: #E6E6E6;
  border-radius: $border-radius-round;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $color-blue;
  border-radius: $border-radius-round;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba($color-button-background, 0.75);
}

/* Media Queries */
// Mobile
@media (max-width: $tablet-min-breakpoint) {
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  .row {
    display: flex;
    flex-direction: column;
}
}