@import "scss/base.scss";

$instruct-width: 80%;
$form-width: 30%;
$form-mobile-width: 80%;
$demo-width: 90%;
$logo-height: 5rem;

.content {
  @include formContent();
  background-color: $color-background-white-secondary;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: $full;
  box-sizing: border-box;
  background-color: $color-background;

  .logo {
    height: $logo-height;
  }

  h4{
    margin-top: $margin-30;
  }
}

.loginContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: $full;
  height: $full;

  .Demo {
    display: $hide;
    width: $demo-width;
    padding: $padding-large;
  }
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {
  .loginContent {
      width: $full;
  }

}

// Mobile
@media (max-width: $tablet-max-breakpoint) {
  .loginContent {
    display: $hide;
  }
  
  .content {
    background-color: $color-background;

    .form {
      width: $form-mobile-width;
    }
  }

  .instructions {
    width: fit-content
  }
}