@import "scss/base.scss";
@import "scss/tables.scss";

$table-height: 530px;

.notificationPage{
    padding: $padding-10 $padding-30;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: inherit;
}

.notificationTitle{
    margin: $margin-5;
}

.notificationTableWrapper{
    @include pageContentWrapper();
}
.notificationTable{
    @include scrollableContent();
    display: flex;
    flex-direction: column;
    flex: 1;
}
/* Media Queries */
// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {
}