@import "scss/base.scss";

$loading-flag-max-width: 80%;

.loadingContainer {
  width: $full;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: $full;
  padding-top: $padding-60;
}

.loadingFlag {
  width: $loading-flag-max-width;
}

.loadingLabel {
  font-weight: $font-weight-bold;
  color: $color-text-loading;
  font-size: $font-size-large;
  margin-top: $no-margin;
}

.dot1 {
  animation: visibility 1.5s linear infinite;
}

@keyframes visibility {
  0% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.dot2 {
  animation: visibility2 1.5s linear infinite;
}

@keyframes visibility2 {
  0% {
    opacity: 0;
  }
  21% {
    opacity: 0;
  }
  22% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.dot3 {
  animation: visibility3 1.5s linear infinite;
}

@keyframes visibility3 {
  0% {
    opacity: 0;
  }
  43% {
    opacity: 0;
  }
  44% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

/* Media Queries */
// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {
}

// // Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {
}
