@import "scss/base.scss";
@import "scss/tables.scss";

$table-min-height: 400px;
$row-height: 60px;
$description-width: 500px;
$button-width: 55%;
$questionnaire-modal-height: 80%;
$questionnaire-modal-max-width: 1000px;
$questionnaire-modal-mobile-width: 90%;
$questionnaire-modal-mobile-height: 90%;
$status-column-width: 10%;
$status-padding-left-alignment: 18px; // Aligns the status header with the status bubbles according to Figma
$client-no-min-width: 18%;

.company {
    @include pageHeader();
}

.filterButtons {
    @include filterButtons();
}

.clientTableContainerWrapper {
    @include pageContentWrapper();
}

.clientTableContainer {
    @include scrollableContent();
    display: flex;
    flex-direction: column;
    width: $full;
}

// New Application Modal
.reviewApplication {
    width: $modal-width;
    max-width: $questionnaire-modal-max-width;
    height: $questionnaire-modal-height;
    position: fixed;
    top: $half;
    left: $half;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
}

.reviewApplicationHeader {
    border-bottom: solid $border-size;
    border-color: $color-light-grey;
    padding-bottom: $padding-10;
}

// Media Queries
// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {
    .clientName, .requestedOn {
        display: none;
    }

    .clientNo, .applicantName, .status {
        display: table-cell;
        width: calc($full / 3);
    }

    .filterButtons {
        flex-wrap: wrap;
        row-gap: $margin-10;
        
        .newAppButton, .filter, .sort {
            flex: 1;
        }

        .searchBar {
            width: $full;
        }
    }

    .clientTable th, .clientTable tr {
        width: calc($full / 3);
    }

    .description {
        display: $hide;
    }

    .reviewApplication {
        width: $questionnaire-modal-mobile-width;
        height: $questionnaire-modal-mobile-height;
    }
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {
    .clientNo {
        min-width: $client-no-min-width;
    }
    
    .status {
        width: $status-column-width;
    }

    .viewButton {
        display: $hide;
    }

    .searchBar {
        width: $full;
    }
}

.approved {
    background-color: $color-int-status-approved;
    width: $status-bubble-width;
}

.pending {
    background-color: $color-int-status-pending-review;
    width: $status-bubble-width;
}

.rejected {
    background-color: $color-int-status-rejected;
    width: $status-bubble-width;
}

.nameWrapper {
    display: flex;
    align-items: center;
    column-gap: $padding-small;

    svg {
        flex-shrink: 0;
    }
}
