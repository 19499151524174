@import "scss/base.scss";

.backArrow {
    border-width: $border-size;
    border-style: solid;
    border-radius: $border-radius-round;
    border-color: $color-grey;
    display: flex;

    &:hover {
        background-color: $color-background-hover;
    }
}