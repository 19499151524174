@import "scss/base.scss";

.layoutParent {
    display: flex;
    height: $full;
    width: $full;
    flex-direction: column;
}

.layoutContent{
    display: flex;
    flex: 1;
    flex-direction: row;
    overflow: hidden;
}

.leftSideBar{
    display: flex;
}



// Desktop
@media (min-width: $tablet-max-breakpoint) {

}

// Mobile
@media (max-width: $tablet-max-breakpoint) {

    .leftSideBar {
        display: $hide;
    }

    .bottomSidebar {
        margin-top: auto;
        margin-bottom: $margin-small;
    }
}