@import "scss/tables.scss";

$cell-height: 50px;
$status-height: 20px;
$progress-bar-width: 40%;
$progress-bar-height: 16px;
$progress-bar-top: 4px;

.approved {
    background-color: $color-file-status-closed;
    width: $status-bubble-width;
}

.pendingReview {
    background-color: $color-int-status-pending-review;
    width: $status-bubble-width;
}

.getStarted {
    background-color: $color-int-status-get-started;
    width: $status-bubble-width;
}

.active {
    background-color: $color-int-status-active;
    width: $status-bubble-width;
}

.rejected {
    background-color: $color-int-status-rejected;
    width: $status-bubble-width;
}

.progressContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    gap: 10px;
}

.progressBar {
    position: relative;
    width: $progress-bar-width;
    height: $progress-bar-height;
    border-radius: $border-radius-very-round;
    background-color: $color-progress-bar-grey;
    opacity: $color-progress-bar-opacity;
    overflow: hidden;
    flex-shrink: 0;
}

.progressText {
    white-space: nowrap;
    flex-shrink: 0;
    display: flex;
    align-items: center;
}

.progressFill {
    height: $full;
    border-radius: $border-radius-very-round;
    background-color: $color-blue; 
    width: 0; 
}

/* Media Queries */
// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {
    .lawyer, .cases {
        display: $hide;
    }
    .headers {
        display: $hide;
    }
    .textTitle{
        text-align: left;
    }
    .statusApproved, .statusPending, .statusInProgress, .statusActive, .statusGetStarted, .statusRejected {
        float: right;
    }
    
    .progressContainer {
        justify-content: flex-start;
        margin-top: 5px;
    }
}

// Desktop
@media (min-width: $desktop-min-breakpoint) {
    .status {
        margin-left: auto;
    }
    .mobileStatus {
        display: $hide;
    }
}