@import "scss/base.scss";

$logo-height: 5rem;
$logo-width: 16rem;
$logo-desktop-width: 30%;
$min-login-width: 300px;

.content {
    @include formContent();
}

.loginContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: $full;
    height: $full;
}

.logo {
    height: $logo-height;
    width: $logo-width;
}

.companyLogo {
    height: $full;
    width: $full;
}

.loginModal {
    width: $modal-width;
}

.loginTitle {
    text-align: center;
}

.loginPicture {
    display: $hide;
    width: $full;
    height: $full;
    background-color: $color-background-white-secondary;
}

.loginPicture .loginPictureComponent {
    margin: auto;
}


/* Media Queries */
// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {
    
}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) {
    .loginContent {
        width: $logo-desktop-width;
        min-width: $min-login-width;
        margin-left: $margin-large;
        margin-right: $margin-large;
    }

    .loginPicture {
        display: flex;
    }
}