@import 'scss/base.scss';

.contentWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}

// Desktop
@media (min-width: $tablet-max-breakpoint) {
    .contentWrapper {
        padding: $padding-20 $padding-40 $padding-20 $padding-40;
        background-color: $color-background-white-secondary;
    }
}

// Mobile
@media (max-width: $tablet-max-breakpoint) {
    .contentWrapper {
        padding: $padding-10 $padding-20 $padding-20 $padding-20;
        background-color: $color-background;
    }
}