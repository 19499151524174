@import "scss/base.scss";

.noDataContainer {
  background-color: $color-background;
  background-color: $color-background;
  width: $full; 
  color: $color-text;
  text-align: center; 
  display: flex;      
  flex: 1;
  flex-direction: column;     
  justify-content: center; 
  align-items: center;     
  text-align: center; 

  .title {
    margin: $no-margin $no-margin $margin-10 $no-margin;
    color: $color-text-subname;
  }
  
  .description {
    margin: $no-margin $no-margin $margin-10 $no-margin;
    color: $color-text-subname; 
  } 
}  