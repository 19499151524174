@import "scss/base.scss";
@import "scss/tables.scss";

.rejectButton{
    border: none;
    background: none;
    flex-shrink: 0;
    min-width: 24px;
    margin-left: 5px;
}

.questionDiv {
    width: $full;
    display: flex;
    flex-direction: row;
    margin-top: $margin-10;
    overflow: visible;
    position: relative;
    &.documentUpload {
        align-items: start;
        .rejectButton {
            margin-top: $margin-6;
        }
    }
}
    
.valueColorClass {
    color: $color-black;
}

.placeholderColorClass {
    color: $color-input-placeholder;
}

.dropdownWrapperClass {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}