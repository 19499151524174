@import "scss/base.scss";

.xlsxViewer {
    margin: 20px;
  
    .sheet {
      margin-bottom: 30px;
  
      .sheetName {
        text-align: center;
        font-size: 24px;
        margin-bottom: 10px;
      }
  
  
      .sheetTable {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
  
        th, td {
          border: 1px solid $color-grey;
          text-align: left;
          padding: 8px;
        }
  
        th {
          background-color: $color-light-grey;
          font-weight: bold;
        }
  
        tr:nth-child(even) {
          background-color: $color-header-background-grey;
        }
      }
    }
  }
  