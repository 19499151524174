@import "scss/base.scss";

$form-width: 90%;
$dob-width: 45%;
$marriage-width: calc(50% - 2*$margin-5);

.formContent {
    margin-top: $margin-20;
    display: flex;
    flex: 1;
    overflow: hidden;

    .gridContainer {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: $padding-16;
      }
      

    .applicationForm {
        width: $full;
        height: $full;
        overflow: auto;
        padding-left: $padding-20;
        padding-right: $padding-20;
        display: flex;
        flex-direction: column;
        row-gap: $padding-10;

        .title {
            margin-bottom: $margin-10;
            border-bottom: $border-size solid $color-submit-button-background;
            margin-top: $margin-10;
        
            h4 {
                color: $color-submit-button-background;
                text-decoration: $hide;
                margin: $no-margin;
                margin-bottom: $margin-10;
                font-size: $font-size-smallText;
                font-weight: $font-weight-normal;

                span {
                    color: $color-submit-button-background;
                    padding-right: $padding-20;
                    padding-left: $padding-10;
                    display: inline-block;
                    text-decoration: $hide;
                    border: none;
                }
            }
        }
    }

    .dob {
        width: $dob-width;
        margin: $margin-5;
    }

    .marriage {
        width: $marriage-width;
        margin: $margin-5;
    }

    .fileUpload {
        font-size: $font-size-smallText;
    }

    .fileUploadSubtext {
        font-size: $font-size-smallerText;
        margin-top: $margin-5;
    }

    .rowColumnOne {
        display: block;
        flex: 1;
        margin: $margin-5 $margin-5;
    }
    
    .rowColumnTwo {
        display: block;
        flex: 2;
        margin: $margin-5 $margin-5;
    }
    
    .fileColumn {
        display: block;
        flex: 1;
        margin: $margin-5;
    }
    
    .submitSection {
        display: flex;
        justify-content: center; 
        align-items: center; 
        width: $full;
    }
    
    .submitButton {
        width: $half;   
    }

    .familyMembers {
        font-size: $font-size-smallerText;
        font-weight: $font-weight-bold;
        margin-bottom: $margin-5;
    }
}

/* Media Queries */

// Desktop
@media only screen and (min-width: $tablet-max-breakpoint) {

}

// Mobile
@media (max-width: $tablet-min-breakpoint) {
    .rowColumn {
        margin: $margin-10 $no-margin;
    }

    .submitButton{
        width: $full;
        margin-top: $no-margin;
    }

    .formContent {
        .gridContainer {
            grid-template-columns: minmax(0, 1fr);
        }
    }
}
