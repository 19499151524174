@import "scss/base.scss";

$row-width: 90%;
$row-text-width: 80%;
$table-row-height: 25px;

.notificationRow{
    display: flex;
    align-items: center;
    font-weight: bold;
    width: $row-width;
    cursor: pointer;
    
    .notificationRowIcon{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $padding-10;
    }

    .notificationRowContent{
        display: flex;
        align-items: start;
        flex-direction: column;
        justify-content: center;
    }

    .notificationTextDetails{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: x-small;
        flex-direction: column;
        width: $full;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;

        a {
            display: flex;
            justify-content: start;
        }
    }

    .notificationDate{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: xx-small;
    }
}

.readNotification{
    color: $color-input-grey;

    a {
        color: $color-input-grey;
    }
}

.unreadNotification{
    color: $color-black;
}

.table{
    height: $table-row-height;
    margin: $margin-10;

    .notificationRowIcon{
        display: flex;
        justify-content: start;
    }

    .notificationRowContent{
        display: flex;
        justify-content: start;
    }

    .notificationTextDetails{
        display: flex;
        flex-direction: row;
        justify-content: start;

        a {
            margin: $margin-5;
        }
    }
}

.dropdown{
    height: $full;
    margin: $padding-5;

    .notificationRowContent{
        width: $row-text-width;
    }

    .removeButton{
        margin: $no-margin $margin-10;
    }
}

/* Media Queries */
// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {
    .notificationTextDetails{
        
    }
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {
    .viewButton {
        display: $hide;
    }
}