@import "scss/base.scss";

$label-width: 95%;
$form-mobile-width: 80%;
$line-spacing: 1.2;

.field {
    margin-bottom: $margin-20;
}

.submitButton {
    width: $full;
    margin: auto;
    margin: $margin-medium $no-margin;
}

.instructionBullets {
    margin-top: $margin-20;
    line-height: $line-spacing;
    color: $color-text-black;
}

.instructionsCenter {
    display: flex;
    justify-content: start;
    text-align: start;
    margin-top: $no-margin;
  }

.messageDiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

.error{
    color: $color-form-error
}

.success{
    color: $color-success
}

.newPassForm {
    width: $form-mobile-width;
}

// Desktop
@media only screen and (max-width: $desktop-min-breakpoint) {
    .newPassForm {
        width: fit-content;
    }
}

// This is for mobile styling
@media (max-width: $tablet-max-breakpoint) {
}