@import "scss/base.scss";
@import "scss/base.scss";

$label-width: 95%;
$form-mobile-width: 80%;
$eye-icon-top: 43px;
$eye-icon-right: 15px;

.passwordContainer {
    position: relative;

    svg {
        display: inline;
        position: absolute;
        right: $eye-icon-right;
        top: $eye-icon-top;
        cursor: pointer;
    }

    label {
        font-size: $font-size-smallText;
    }

    input {
        padding-right: $padding-30;
    }
}

.labels {
   width: $label-width;
   display: inline-block;
   font-size: $font-size-smallText;
}

// Desktop
@media only screen and (max-width: $desktop-min-breakpoint) {
}

// This is for mobile styling
@media (max-width: $tablet-max-breakpoint) {
}