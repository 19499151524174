@import "scss/base.scss";
@import "scss/tables.scss";

.companyName {
    display: flex;
    align-items: center;
    column-gap: $padding-small;
}

.cases {
    text-align: center;
}

/* Media Queries */
// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {
    .lawyer, .cases {
        display: $hide;
    }
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {

}