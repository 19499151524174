@import "scss/base.scss";

.label {
 margin-right: $margin-medium;
} 

.required, .alert {
  color: $color-alert;
  font-size: $font-size-smallText;
}

.field {
  box-sizing: border-box;
  border: $border-size solid $color-border;
  height: $input-min-height;
  padding: $no-padding $padding-medium;
  width: $full;
  background-color: $color-background-white-secondary;
  opacity: 0.7;
  border-radius: $border-radius-round-slight;
}