@import "scss/base.scss";
@import "scss/tables.scss";

$title-number-width: 40px;
$dropdown-content-max-height: 48px;

.section {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: $no-margin;
    margin-left: $margin-10;
    margin-right: $margin-20;
}

.line {
    border-bottom: $border-bottom-width solid;
    width: $full;
    margin-bottom: $margin-5;
}

.title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: $margin-15;
    font-weight: $font-weight-normal;
    margin-top: $margin-10;
    margin-bottom: $margin-5;
}

.titleContent {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.titleNumber {
    width: $title-number-width;
}

.rejectButton {
    border: none;
    background: none;
}

.question {
    display: flex;
    margin-bottom: $margin-10;
    padding: $no-padding $padding-10;
    height: auto;
    min-height: $dropdown-content-max-height;
    box-sizing: border-box;
    overflow: visible;
    flex-shrink: 0;
    position: relative;
}

.questionRow{
    display: flex;
    flex-direction: row;
    margin-bottom: $margin-5;
    align-items: start;
    flex-wrap: wrap;
    padding-right: 25px;
    position: relative;
}

.defaultColorClass {
    .line {
        border-bottom-color: $color-submit-button-background;
    }
    .title {
        color: $color-submit-button-background;
    }
}

.rejectedColorClass {
    .line {
        border-bottom-color: $color-alert;
    }
    .title {
        color: $color-alert;
    }
}

.sectionLabel{
    margin-left: $margin-20;
}

/* Media Queries */
// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {
    .question{
        width: $full !important;
    }
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {
    
}