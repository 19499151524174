@import "scss/base.scss";
@import "scss/tables.scss";

.tableValueColumn {
    display: flex;
    align-items: center;
    column-gap: $padding-small;
}

.cases {
    text-align: center;
}

.getStarted {
    background-color: $color-int-status-get-started;
    width: $status-bubble-width;
    white-space: nowrap;
}

.active {
    background-color: $color-int-status-active;
    width: $status-bubble-width;
    white-space: nowrap;
}

.pendingReview {
    background-color: $color-int-status-pending-review;
    width: $status-bubble-width;
    white-space: nowrap;
}

.rejected {
    background-color: $color-int-status-rejected;
    width: $status-bubble-width;
    white-space: nowrap;
}

.approved {
    background-color: $color-int-status-approved;
    width: $status-bubble-width;
    white-space: nowrap;
}

/* Media Queries */
// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {
    .lawyer, .cases {
        display: $hide;
    }
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {

}