@import "scss/base.scss";

$instruct-width: 80%;
$form-width: 30%;
$form-mobile-width: 80%;
$form-min-width: 300px;
$demo-width: 90%;
$logo-height: 5rem;

.content {
  background-color: $color-background-white-secondary;
  @include formContent();
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: $full;
  width: $form-width;
  min-width: $form-min-width;
  box-sizing: border-box;
  background-color: $color-background;
  padding-left: $padding-30;
  padding-right: $padding-30;
}

.logo {
  height: $logo-height;
}

.loginContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: $full;
  height: $full;
}

.displaySix {
  display: flex;
}

.Demo {
  display: $hide;
  width: $demo-width;
  padding: $padding-large;
}

.forget {
  margin-top: $margin-10;
  margin-bottom: $margin-10;
}

.barcode {
  margin-bottom: $margin-20;
  margin-top: $margin-20;
}

.instructions {
  margin-top: $no-margin;
  margin-bottom: $margin-20;
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {
  .loginContent {
      width: $full;
  }

}

// Mobile
@media (max-width: $tablet-max-breakpoint) {
  .loginContent {
    display: $hide;
  }
  .content {
    background-color: $color-background;
  }

  .form {
    width: $form-mobile-width;
    padding: $no-padding;
  }

  .instructions {
    width: fit-content
  }
}