@import "scss/base.scss";

$input-height: 50px;

.inputContainer {
  // TODO: If we're using a mixin, we shouldn't need to have
  // height/min-height/padding/etc here since all our inputs are going
  // to look the same. They should go into the mixin.
  // Styles here are to overwrite the mixin, not add to it. 
  @include formInputStyle();
  height: $input-height;
  min-height: $input-height;
  padding: $no-padding $padding-medium;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 100%; /* Ensure it doesn't exceed parent width */
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
  &:hover {
    background-color: $color-background-hover;
  }
  .input {
    outline: $hide;
    border: $hide;
    display: block;
    margin: $no-margin;
    padding: $no-padding;
    background-color: transparent;
    font-size: $font-size-slightly-smallText;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;

    &::placeholder {
      font-style: normal;
    }
  }

  &.errorBorder {
    border-color: $color-alert;  
  }
}

.errorText {
  color: $color-alert !important;
}

.wrapperDiv{
  width: $full;
  min-width: 0; /* Allow flexbox to shrink this item below content size */
  max-width: 100%;
}


.alert {
  color: $color-alert;
  font-size: $font-size-smallText;
}

/* Media Queries */
// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {
  .tableQuestionMobileStyle{
    .inputContainer {
      border: none;
      padding: $no-padding;
      cursor: pointer;
      &:hover {
        background-color: inherit; 
        color: inherit; 
        border: none;
      }
    }
  }
  
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {
  .inputContainer {
    &:hover {
      background-color: $color-background-hover;
    }
  }
}