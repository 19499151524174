@import "scss/base.scss";

$label-width: 95%;
$form-mobile-width: 80%;
$min-mobile-width: 280px;

.field {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-right: $margin-small;
    width: $full;
    margin-bottom: $margin-20;
}

.submitButton {
    width: $full;
    margin: auto;
    margin: $margin-medium $no-margin;
}

.field input {
    &::placeholder {
        font-style: normal;
      }
}

.topContainer {
    width: $full;
}

.instruct {
    margin-top: $margin-20;
    line-height: 1.2;
    color: $color-text-subname;
}

.success {
    height: $input-min-height-small;
    margin-top: $margin-20;
    color: $color-blue;
    text-align: center;
}

.responseMessage{
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Media Queries */
// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {
    .topContainer{
        width: $full;
    }
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {

}