@import "scss/base.scss";

.resetPassForm {
    height: $full;
}

.labels {
   display: inline-block;
   font-size: $font-size-smallText;
}

.field {
    width: $full;
    margin-bottom: $margin-30;
}

.submitButton {
    width: $full;
    margin: $margin-medium $no-margin;
    margin-bottom: $margin-40;
}

.instruct {
    color: $color-text-subname;
}

.success {
    color: $color-success;
    text-align: center;
    font-weight: bold;
    font-size: $font-size-large;
}

.error {
    color: $color-alert;
    text-align: center;
}   