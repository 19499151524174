@import "scss/base.scss";

$checkbox-width: 20px;
$checkbox-height: 20px;
$checkbox-min-height: 55px;

.checkboxContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  max-width: 100%;
  min-width: 0;
  box-sizing: border-box;
  &:hover {
    background-color: $color-background-hover;
  }
  
  .label {
    position: relative;
    padding-left: $padding-35;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    min-height: $checkbox-height;
    box-sizing: border-box;
  
    .checkboxInput {
      opacity: 0;
      position: absolute;
  
      &:checked + span {
        &:before {
          content: '\2713'; // Checkmark character
          font-size: $font-size; 
          font-weight: $font-weight-bolder;
          color: $color-text; 
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  
    .customCheckbox {
      position: absolute;
      top: 2px; // Adjust to align with the first line of text
      left: 0;
      width: $checkbox-width;
      height: $checkbox-height; 
      border: $border-size-slightly-big solid $color-input-border; 
      background-color: transparent; 
      border-radius: $border-radius-slightly-round; 
      box-sizing: border-box;
    }

    .text {
      color: $color-input-dark;
      word-break: break-word;
      width: calc(100% - 5px); // Slightly reduce width to prevent overflow
      line-height: 1.4;
      box-sizing: border-box;
      overflow-wrap: break-word;
      
      &.redText {
        color: $color-alert;
      }
    }
  }
  
  .alert {
      color: $color-alert;
      font-size: $font-size-smallText;
  }

  .errorDiv{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .errorBorder {
    border-color: $color-alert !important;  
  }
}