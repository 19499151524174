@import "scss/base.scss";
@import "scss/tables.scss";

$table-height: 650px;

.searchPage{
    padding: $padding-10 $padding-30;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: inherit;
}

.searchTitle{
    margin: $margin-5;
}

.searchTableWrapper{
    @include pageContentWrapper();
    padding: $padding-30 $padding-10;
}

.searchTable{
   @include scrollableContent();
    display: flex;
    flex-direction: column;
    flex: 1;
}

.noResultsSection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.helpfulBeaver{
    max-width: $full;
    height: auto;
    margin: $margin-10;
}

.noResultsTitle{
    color: $color-blue;
}

.noResultsDescription{
}
/* Media Queries */
// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {
}