@import "scss/base.scss";

$label-width: 95%;

.labels {
   width: $label-width;
   display: inline-block;
   font-size: $font-size-smallText;
   margin-bottom: $margin-10;
}

.field {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-right: $margin-small;
    width: $full;
    font-size: $font-size-h5;
    margin-bottom: $margin-30;
}

.submitButton {
    width: $full;
    margin: $no-margin;
    min-width: $min-button-width;
}

.field input {
    &::placeholder {
        font-style: normal;
      }
}

.passwordContainer {
    position: relative;
    .field {
        display: inline-block;
    }

    svg {
        display: inline;
        position: absolute;
        right: 20px;
        top: 18px;
        cursor: pointer;
    }
}

.passwordLabel {
    margin-bottom: $margin-10;
}