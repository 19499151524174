@import "scss/base.scss";

$title-icon-size: 4rem;

.infoBlock {
  padding: $padding-large;
  margin: auto;
  border-top: solid $border-size $color-border;
}

.titleClass{
  display: flex;
  flex-direction: row;
  color: $color-blue;
  align-items: center;
  cursor: pointer;
  
}

.titleText{
  display: flex;
  flex: 1;
  font-size: $font-size-h2;
  font-weight: bold;
  margin: 0;
}

.titleIcon{
  display: flex;
  height: $title-icon-size;
  width: $title-icon-size;
  justify-content: center;
  align-items: center;
}

.textBlockClass{
  padding-left: $title-icon-size;
  text-overflow: wrap;
}


/* Media Queries */
// Tablet
@media only screen and (max-width: $desktop-min-breakpoint) {
  .titleText{
    font-size: $font-size-slightly-large;
    font-weight: bold;
  }
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {
  .titleText{
    font-size: $font-size-h2;
    font-weight: bold;
  }
}
