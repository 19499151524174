@import "scss/base.scss";

$row-width: 90%;
$row-text-width: 80%;
$table-row-height: 25px;




.searchBarRow{
    border-bottom: solid $border-bottom-width $color-border;

}

.searchBarRowContent{
    margin: $margin-5;
    padding: $padding-5 $padding-10;
    &:hover {
        border-radius: $border-radius-round-mid;
        background-color: $color-background-hover-secondary;
        cursor: pointer;
    }
}

.searchBarType{
}

.searchBarValue{
}

/* Media Queries */
// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {
    .viewButton {
        display: $hide;
    }
}