@import "scss/base.scss";

$grey-background: rgba(0, 0, 0, 0.5);
$z-index: 100;
$top: 0;
$left: 0;

.modalWrapper {
  position: fixed;
  top: $top;
  left: $left;
  width: $full;
  height: $full;
  background: $grey-background;
  z-index: $z-index;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.modalBackdrop {
  background: $color-background;
  padding: $padding-large;
  background: linear-gradient($color-background, $color-background) padding-box,
  linear-gradient(to right, $color-blue, $color-yellow) border-box;
  border-radius: $border-radius-round-slight;
  border: $border-size solid transparent;
  overflow: hidden;

  .modalHeader {
    display: flex;
    margin-left: $margin-10;
    margin-bottom: $margin-10;

    h2, h3 {
      margin: $no-margin;
    }

    .exitButton {
      margin-left: auto;
      color: $color-blue;
      cursor: pointer;
    }
  }
}

.headerLabel{
  font-size: $font-size-large;
}

/* Center FileViewer and related document components */
:global {
  .pg-viewer-wrapper {
    text-align: center !important;
    width: 100% !important;
    overflow: auto !important;
  }
  
  .pg-viewer {
    margin: 0 auto !important;
    display: inline-block !important;
    min-width: 80% !important;
  }
  
  /* Specific PDF viewer fixes */
  .react-pdf__Document {
    display: inline-block !important;
    text-align: center !important;
  }
  
  .react-pdf__Page {
    margin: 0 auto !important;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2) !important;
  }

  /* Canvas maintains original size */
  canvas {
    max-width: none !important;
  }
}