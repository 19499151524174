@import "scss/base.scss";

$input-height: 175px;
$container-padding-top: 4px;
$container-padding-bottom: 8px;
$scrollbar-margin-y: 3px;
$label-height: 17.5px;

.wrapperContainer {
  width: $full;
  min-width: 0;
  max-width: 100%;
  overflow: visible;
  position: relative;
  
  .inputContainer {
    @include formInputStyle();
    min-height: $input-height;
    padding: $container-padding-top $no-padding $container-padding-bottom $padding-medium;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: $full;
    max-width: 100%;
    &:hover {
      background-color: $color-background-hover;
    }
    
    .label {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
     }

    .textarea {
      outline: $hide;
      border: $hide;
      display: block;
      background-color: transparent;
      font-size: $font-size-slightly-smallText;
      resize: none;
      text-overflow: ellipsis;
      width: 100%;
      &::placeholder {
        font-style: normal;
      }
    }

    &.errorBorder {
      border-color: $color-alert; 
    }

    ::-webkit-scrollbar-track {
      margin-top: calc(0px - $container-padding-top + $scrollbar-margin-y - 2px);
      margin-bottom: calc(0px - $container-padding-bottom + $scrollbar-margin-y);
    }
  }

  .lockedMultilineInputContainer {
    min-height: $input-height;
    max-height: $input-height;
    overflow: auto;
    justify-content: start;

    .lockedMultilineInput {
      white-space: pre-wrap;
    }
  }

  .reviewerComments {
    .label {
      margin-top: $margin-10;
      margin-bottom: $margin-5;
    }
    ::placeholder {
      color: transparent;
    }
  }
  .applicantComments {
    .label {
      display: $hide;
    }
  }
}

.errorText {
  color: $color-alert !important;
}

.alert {
  color: $color-alert;
  font-size: $font-size-smallText;
}

/* Media Queries */
// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {
   
}