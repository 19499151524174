@import "scss/tables.scss";
@import "scss/base.scss";

.active {
    background-color: $color-file-status-active;
    width: $status-bubble-width;
}

.pendingReview {
    background-color: $color-file-status-pending-review;
    width: $status-bubble-width;
}

.submitted {
    background-color: $color-file-status-submitted;
    width: $status-bubble-width;
}

.closed {
    background-color: $color-file-status-closed;
    width: $status-bubble-width;
}

.onHold {
    background-color: $color-file-status-on-hold;
    width: $status-bubble-width;
}

.companyName {
    display: flex;
    align-items: center;
    column-gap: $padding-small;
}

/* Media Queries */
// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {
    .description {
        display: $hide;
    }
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {

}