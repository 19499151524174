@import "scss/base.scss";

@mixin animationKeep {
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.animatedDatePickerWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: $full;
  height: $full;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.datePicker {
  * {
    box-sizing: border-box;
  }

  width: 24rem;
  min-width: 200px;
  max-width: 600px;
  height: 20rem; ///20rem
  min-height: 150px;
  max-height: 500px;
  background: $color-background;
  border-radius: $border-radius-round-slight;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: $padding-large;

  .topBar {
    width: $full;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $no-padding $padding-small;

    .arrowLeft {
      cursor: pointer;

      svg {
        color: $color-grey-2;
        stroke-width: $border-size;
      }
    }

    .header {
      font-size: $font-size-h4;
      cursor: pointer;
      color: $color-light-blue;
    }

    .arrowRight {
      cursor: pointer;

      svg {
        color: $color-grey-2;
        stroke-width: $border-size;
        transform: scaleX(-1);
      }
    }
  }

  .animationWrapper {
    width: $full;
    height: $full;
    overflow: hidden;
    position: relative;
  }

  .mainTable {
    width: $full;
    height: $full;
    text-align: center;
    column-gap: $spacing-large;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: $padding-small;
    flex-shrink: 0;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .headerRow {
      padding: $no-padding $padding-small;
    }

    .row {
      width: $full;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;

      .headerCell {
        padding: $padding-small $no-padding;
        flex: 1;
        color: $color-grey-1;
      }

      .cell {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;

        height: 2.5rem;
        min-width: 2.5rem;
        padding: $no-padding $padding-small;
        border-radius: $border-radius-slightly-round;

        cursor: pointer;

        color: $color-blue;

        &:hover {
          background-color: rgba($color-blue, 0.1);
          color: $color-blue;
        }
      }

      .monthYearCell {
        min-width: 3.5rem;
      }

      .currentCell {
        background-color: $color-blue;
        color: $color-text-secondary;
      }

      .greyedCell {
        color: $color-light-grey;
        cursor: default;

        &:hover {
          background-color: transparent;
          color: $color-light-grey;
        }
      }

      .greyedCell2 {
        color: $color-grey-2;
        cursor: default;

        &:hover {
          background-color: transparent;
          color: $color-grey-2;
        }
      }
    }
  }
}

.currentDate {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $padding-large;
  color: $color-grey-2;
  cursor: pointer;
}

.hide {
  transform: translateY(100%);
  max-height: 0;
  display: none;
}

.invisible {
  opacity: 0;
  z-index: -1000;
}
