@import "scss/base.scss";
@import "scss/tables.scss";

$progress-bar-width: 40%;
$progress-bar-height: 16px;
$progress-bar-top: 4px;
$upload-document-width: 80%;
$application-number-width: $full;
$modal-margin-side: 5%;
$modal-row-height: 60px;
$uploading-text-height: 50px;

.documents {
    @include pageHeader();
}

.filterButtons {
    @include filterButtons();
}

.documentContainerWrapper {
    @include pageContentWrapper();
    flex-direction: column;
    padding: $padding-40 $padding-30 $padding-40 $padding-30;
}

.documentContainer {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    row-gap: $padding-40;
    column-gap: $padding-30;
    overflow: auto;
}

.uploadDocumentModal {
    height: fit-content;
    margin: 0 $modal-margin-side;
    margin-bottom: $margin-60;
    padding: $padding-20;
    overflow-y: auto;

    .modalHeader {
        margin: $no-margin;
        justify-content: center;
        text-align: center;
    }

    .modalContent {
        height: $full;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow-y: auto;
        
        .contentRow {
            width: $full;
            height: $modal-row-height;
            border-bottom: $border-size solid $color-grey;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.applicationNumberContainer {
    width: $application-number-width;
    margin-top: $margin-30;
}

.uploadDocumentContainer {
    width: $upload-document-width;
    margin-top: $margin-40;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.progressBar {
    position: relative;
    top: $progress-bar-top;
    width: $progress-bar-width;
    height: $progress-bar-height;
    border-radius: $border-radius-very-round;
    background-color: $color-progress-bar-grey;
    opacity: $color-progress-bar-opacity;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $margin-20;
    overflow: hidden;

}

.progressFill {
    height: $full;
    border-radius: $border-radius-very-round;
    background-color: $color-blue; 
    width: 0; 
}

.uploadTextContainer {
    min-height: $uploading-text-height;
    height: fit-content;

    :first-child {
        margin-top: 10px;
    }
    
    p {
        font-size: $font-size-smallText;
        text-align: left;
        color: $color-progress-bar-grey;
        opacity: $color-progress-bar-opacity;
        margin: $no-margin;
        
        &.error {
            margin-top: $margin-5;
            color: $color-alert;
            text-wrap: wrap;
            max-width: 70vw;
        }
    }

    .uploadCompleteText {
        color: #2A3192;
        text-align: center;
        padding: 80px;
        font-weight: $font-weight-slightly-bold;
        font-size: $font-size-slightly-large;
        white-space: nowrap;
    }

    .uploadMessagesContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
}

.noDocsWrapper{
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}


/* Media Queries */
// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {
    .filterButtons {
        flex-wrap: wrap;
        row-gap: $margin-10;
        
        .uploadButton, .filter, .sort {
            flex: 1 1;
        }
        .searchBar {
            width: $full;
        }
    }

    .dropDownDirectionClass {
        right: 0;    
    }

    .documentContainerWrapper {
        padding: $padding-20 $padding-10 $padding-20 $padding-10;

        .documentContainer {
            justify-content: center;
            row-gap: $padding-10;
            column-gap: $padding-5;
        }
    }

    .uploadDocumentModal {
        padding: $padding-10;
    }

    .uploadTextContainer {

        .uploadCompleteText {
            white-space: wrap;
        }
    }
}
@media only screen and (max-width: $tablet-min-breakpoint) {
    .applicationNumberContainer {
        margin-top: $margin-45;
    }
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {
    .dropDownDirectionClass {
        left: 0;    
    }    
}

// Narrow
@media only screen and (max-width: $narrow-screen-width) {
    .dropDownDirectionClass {
        left: 0;    
    }
}