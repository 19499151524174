@import 'scss/base.scss';

$card-width: 280px;
$card-height: 360px;
$card-mobile-width: 140px;
$card-mobile-height: 260px;
$thumbnail-wrapper-height: 65%;
$view-document-width: 130px;
$view-document-min-height: 30px;
$bubble-height: 30px;
$document-modal-min-height: 30%;
$document-modal-height: 80%;
$document-modal-left: 10%;
$save-button-max-width: 500px;
$save-button-width: 35%;


.card {
    width: $card-width;
    height: $card-height;
    padding: $padding-large;
    border-radius: $border-radius-slightly-round;
    border: $border-size solid $color-grey;
    display: flex;
    flex-direction: column;
}

.thumbnailWrapper {
    margin-bottom: $margin-medium;
    height: $thumbnail-wrapper-height;
    
    img {
        width: $full;
        height: $full;
    }
}

.cardTitleSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: $font-size-slightly-large;
    font-weight: $font-weight-bold;
    margin: $no-margin;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
}

.date {
    color: $color-text;
    margin-top: $margin-small;
    margin-bottom: $no-margin;
}

.footer {
    margin-top: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.viewDocument {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius-round;
    min-height: $view-document-min-height;
    font-size: $font-size-smallText;
    margin: $no-margin $margin-small $no-margin $no-margin;
    padding: $no-padding $padding-10;
}

.buttonTitle {
    margin: $no-margin $margin-10;
}

.bubble {
    height: $bubble-height;
    background-color: $color-blue;
    border-radius: $border-radius-round;
    color: $color-white;
    font-size: $font-size-smallText;
    font-weight: $font-weight-slightly-bold;
    padding: $no-padding $padding-10;
    display: flex;
    align-items: center;
}

.documentModal {
    width: $modal-width;
    height: $document-modal-height;
    min-height: $document-modal-min-height;
    margin-left: $document-modal-left;
    margin-top: $margin-50;
    display: flex;
    flex-direction: column;

    h2 {
        font-size: $font-size-slightly-large;
    }

    img {
        width: $full;
        height: $full;
    }

    .modalContent {
        margin-left: $margin-10;
        height: 90%;
        overflow: auto;
        overflow-wrap: break-word;
        .contentRow {
            width: $full;
            border-bottom: $border-size solid $color-grey;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .modalFooter {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: $margin-10;
        
        .downloadButton {
            width: $save-button-width;
            max-width: $save-button-max-width;
            margin: $no-margin;
            background-color: inherit;
        }
    }
  }

/* Media Queries */
// Mobile + Tablet
@media only screen and (max-width: $tablet-min-breakpoint) {
    .card {
        width: $card-mobile-width;
        height: $card-mobile-height;
        padding: $padding-small;

        .title {
            font-size: $font-size-smallText;
        }

        .date {
            font-size: $font-size-smallText;
            margin-top: $no-margin;
        }

        .viewDocument {
            font-size: $font-size-smallerText;
            font-weight: $font-weight-normal;
        }

        .bubble {
            font-size: $font-size-smallerText;
        }
    }
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {

}