@import "scss/base.scss";
@import "scss/tables.scss";


$header-height: 40px;
$icon-offset: -2px;
.HelpPageHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.helpfulBeaver {
    width: auto;
    height: auto;
}

.imageContainer {
    padding: $padding-25 $no-margin;
}

.HelpPageWrapper {
    @include pageContentWrapper();
    padding: $padding-10;
}

.HelpPageKnowledgeBlocks{
    width: $full;
    overflow-y: auto;
    padding: $no-padding $padding-4;
}

li {
    overflow: wrap;
}

.DropDownTextBlock:not(:first-child) {
    border-top: $border-size solid $color-help-border; /* Adjust the color and style as needed */
}

.boldBlue {
    color: $color-blue;
    font-weight: bold;
}

.boldBlack {
    color: $color-black;
    font-weight: bold;
}

.settingsIconPlaceholder {
    display: inline-block;
    vertical-align: middle; 
    margin-bottom: $icon-offset; 
  }

.bold {
    font-weight:bold;
}

/* Media Queries */
// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {
    .imageContainer{
        display: none;
    }

    .helpPageTitle {
        font-size: $font-size-large;
    
    }
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {

}
