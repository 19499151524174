@import "scss/base.scss";
@import "scss/tables.scss";

$table-min-height: 400px;
$row-height: 60px;
$description-width: 500px;
$button-width: 55%;
$application-modal-height: 80%;
$application-modal-max-width: 1000px;
$application-modal-mobile-width: 90%;
$application-modal-mobile-height: 90%;


.company {
    @include pageHeader();
}

.filterButtons {
    @include filterButtons();
}

.clientTableContainerWrapper {
    @include pageContentWrapper();
}

.clientTableContainer {
    @include scrollableContent();
    display: flex;
    flex-direction: column;
    width: $full;
}

// New Application Modal
.newApplication {
    width: $modal-width;
    max-width: $application-modal-max-width;
    height: $application-modal-height;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
}

.newApplicationHeader {
    border-bottom: solid $border-size;
    border-color: $color-light-grey;
    padding-bottom: $padding-10;
}

/* Media Queries */
// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {
    .filterButtons {
        flex-wrap: wrap;
        row-gap: $margin-10;
        
        .newAppButton, .filter, .sort {
            flex: 1;
        }
        .searchBar {
            width: $full;
        }
    }
    .clientTable {
        th, tr {
            width: calc($full/3);
        }
    }

    .dropDownDirectionClass {
        right: 0;    
    }

    .description {
        display: $hide;
    }

    .newApplication {
        width: $modal-width;
        height: $application-modal-mobile-height;
    }

    .headerDesktop {
        display: $hide;
    }
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {

    .clientTable {
        th:first-of-type, th:nth-of-type(2), td:nth-of-type(2), th:nth-of-type(4) {
            width: calc($half/3);
        }
    }

    .viewButton {
        display: $hide;
    }

    .headerMobile {
        display: $hide;
    }

    .clientTable {
        th, tr {
            width: calc($full/6);
        }
    }

    .dropDownDirectionClass {
        left: 0;    
    } 
}

// Narrow
@media only screen and (max-width: $narrow-screen-width) {
    .dropDownDirectionClass {
        right: 0;    
    }
}