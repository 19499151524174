@import "scss/base.scss";
@import "scss/tables.scss";

$table-container-min-height: 400px;
$client-table-row-width: 200px;
$client-table-row-height: 60px;

.companies {
    @include pageHeader();
}

.filterButtons {
    @include filterButtons();
}

.clientTableContainerWrapper {
    @include pageContentWrapper();
}

.clientTableContainer {
    @include scrollableContent();
    display: flex;
    flex-direction: column;
    width: $full;
}

.cases {
    text-align: center;
}

.noDataMessage {
    margin: $margin-60 $no-margin;
}

/* Media Queries */
// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {
    .lawyer, .cases {
        display: $hide;
    }
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {
    
}