@import "scss/base.scss";
@import "scss/tables.scss";

$table-min-height: 400px;
$row-height: 60px;
$description-width: 500px;
$button-width: 55%;
$questionnaire-modal-height: 80%;
$questionnaire-modal-max-width: 1000px;
$questionnaire-modal-mobile-width: 90%;
$questionnaire-modal-mobile-height: 90%;


.company {
    @include pageHeader();
}

.filterButtons {
    @include filterButtons();
}

.clientTableContainerWrapper {
    @include pageContentWrapper();
}

.clientTableContainer {
    @include scrollableContent();
    display: flex;
    flex-direction: column;
    width: $full;
}

// New Application Modal
.reviewQuestionnaire {
    width: $modal-width;
    max-width: $questionnaire-modal-max-width;
    height: $questionnaire-modal-height;
    position: fixed;
    top: $half;
    left: $half;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
}

.reviewQuestionnaireHeader {
    border-bottom: solid $border-size;
    border-color: $color-light-grey;
    padding-bottom: $padding-10;
}

.questionnaireModal {
    width: $modal-width;
    max-width: $questionnaire-modal-max-width;
    height: $questionnaire-modal-height;
    position: fixed;
    top: $half;
    left: $half;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
}

/* Media Queries */
// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {
    .filterButtons {
        flex-wrap: wrap;
        row-gap: $margin-10;
        
        .newAppButton, .filter, .sort {
            flex: 1;
        }
        .searchBar {
            width: $full;
        }
    }
    .clientTable {
        th, tr {
            width: calc($full/3);
        }
    }

    .description {
        display: $hide;
    }

    .reviewQuestionnaire {
        width: $modal-width;
        height: $questionnaire-modal-mobile-height;
    }
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {

    .clientTable {
        th:first-of-type, th:nth-of-type(2), td:nth-of-type(2), th:nth-of-type(4) {
            width: calc($half/3);
        }
    }

    .viewButton {
        display: $hide;
    }

    .searchBar {
        width: $full;
    }
}