@import "scss/base.scss";

$demo-width: 90%;
$form-desktop-width: 30%;
$line-height-instruct: 1.2;
$instruct-opacity: 0.7;
$loading-container-min-width: 300px;

.content {
  @include formContent;
}

.twoStep {
  margin-bottom: $margin-10;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $full; 
}

.loading {
  width: $half;
  min-width: $loading-container-min-width; 
}

.instructionList {
  line-height: $line-height-instruct;
  color: $color-text-black;
  opacity: $instruct-opacity;
}

.lineby {
  text-align: left;
}

.logo {
  height: 5rem;
}

.twofactorImage{
  width: auto;
  height: auto;
}

.imageContainer{
  height: $full;
  width: $full;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-background-white-secondary
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: $full;
  height: $full;
}

.loginContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: $full;
  height: $full;
  background-color: $color-background-white-secondary;
}

.barcode {
  margin-bottom: $margin-20;
  margin-top: $margin-20;
}

.twoFactorContent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.link{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: $margin-20;
}

.linkButton{
  text-decoration: none;
  color: $color-black;
}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) {
  .loginContent {
      width: $full;
  }
  .form {
    width: $form-desktop-width;
    margin-left: $margin-large;
    margin-right: $margin-large;
  }

}

// Mobile
@media (max-width: $tablet-max-breakpoint) {
  .loginContent {
    display: $hide;
  }

  .imageContainer{
    display: $hide;
  }

  .content {
    background-color: $color-background;
  }

  .instruct {
    all: unset;
    font-size: $font-size-slightly-large;
    color: $color-text-black;
  }
}