@import "scss/base.scss";

$dropdown-height: 50px;
$dropdown-modal-height: 300px;
$top-offset: 22px;
$left-offset: 12px;
$dropdown-bottom-padding: 6px;
$label-top: 4px;
$userAccountField: 40px;
$userAcccountWidth: 800px;
$desktop-dropdown-width: 620px;
$desktop-left-offest: 175px;
$labelWidth: 200px;
$selectedValueWidth: 585px;


.dropDownContainer {
  @include formInputStyle();
  display: flex;
  justify-content: space-between;
  padding: $no-padding $padding-medium;
  max-height: $dropdown-height;
  &:hover {
    background-color: $color-background-hover;
  }
  cursor: pointer;
  // TODO: This should be a shared style
  // TODO: See note about overriding mixins on formInput mixin
  &.locked {
    border: solid $border-size $color-input-placeholder;
  }
  
  &.errorBorder {
    border-color: $color-alert;
  }
}

.lockedInputClass {
  color: $color-input-grey !important;
}

.tableValueBlack{
  color: $color-black !important;
}

.label {
  margin-top: $margin-5;
  text-overflow: ellipsis;
  overflow: hidden;
}

.optionLabel {
  padding-left: $padding-10;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  /* tooltip longpress */
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tooltip {
  border-radius: $radius-small;
  background-color: $color-background-tooltip;
  padding: 4px 12px;
  position: absolute;
  bottom: calc(100% + 12px);
  right: 2.5rem;
  z-index: 99;
  opacity: 0;
  touch-action: none;

  &:before{
    content: "";
    position: absolute;
    border-width: 12px 8px 0 8px;
    border-style: solid;
    border-color: $color-background-tooltip transparent transparent transparent;
    left: 50%;
    top: 100%;
  }
}

.showTooltip {
  opacity: 1;
}

.option {
  color: $color-text-subname;
  &:hover {
    background-color: $color-text-subname;
    color: $color-background;
  }
}

.selectedValue{
  color: $color-black;
}

.greyValue{
  color: $color-input-grey;
  font-size: $font-size-slightly-smallText;
  
  &::placeholder {
    color: $color-input-dark;
  }
}

.modalContainer {
  max-height: $dropdown-modal-height;
  height: fit-content;
}

.backgroundDiv{
  height: $full;
  width: $full;
  background-color: $color-background;
}

.dropDownField {
  z-index: 999;
  position: absolute;
  top: $dropdown-height;
  left: 0;
  width: $full;
  outline: $hide;
  border: $border-size-slightly-big solid $color-border;
  border-radius: $radius-small;
  overflow-y: visible;
  cursor: pointer;
  padding-bottom: $dropdown-bottom-padding;
  font-size: $font-size-slightly-smallText;
  &.tableStyle {
    padding-top: $padding-5;
  }
  
  .dropDownScrollContainer {
    max-height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    will-change: transform;
    transform: translateZ(0);
  }
}

.dropDownSelectParentDiv{
  position: relative;
  display: flex;
  flex-direction: column;
  width: $full;
}

.alert {
  color: $color-alert;
  font-size: $font-size-smallText;
}

.questionnaire {
  .selectedValue {
    display: flex;
    justify-content: start;
    align-items: start;
    font-size: $font-size-slightly-smallText;
  }
}

.questionnaireTable {
  .selectedValue {
    height: $dropdown-height;
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: $font-size-slightly-smallText;
  }

  .dropDownContainer{
    display: flex;
    align-items: center;
  }
}

.documents {
  .dropDownContainer {
    display: flex;
    align-items: center;
  }
  .label {
    display: none;
  }
  .greyValue {
    color: $color-input-dark;
  }
  .selectedValue {
    font-size: $font-size-slightly-smallText;
  }
}
  
.dropDownArrowMobile {
  display: none;
}

.valueSection{
  width: $full;
  text-overflow: ellipsis;
}

.dropDownInfo{
  width: $full;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 10px;
  flex-grow: 1;
  
}

.inputField{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  background-color: transparent;
  text-overflow: ellipsis;
  width: $full;
  max-width: calc(100% - $padding-10);
  overflow: hidden;
  padding: $no-padding;
}

  .inputField:focus {
  outline: none; 
  }

.dropDownArrow {
  display: flex;
  align-items: center;
}

.errorText {
  color: $color-alert !important;
}
  
// Desktop
@media only screen and (min-width: $tablet-max-breakpoint) {
  
  .application {
    .label {
      width: $dropdown-height;
    }
  }
  
  .userSettings {
    display: flex;
    width: $userAcccountWidth;
    padding-left: $padding-10;
    border: none;
    align-items: center;
    font-size: $font-size-slightly-smallText;

    .dropDownContainer{
      padding: $no-padding $padding-10 $no-padding $padding-5;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border: none;
    }

    .dropDownInfo{
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .dropDownField {
      width: $desktop-dropdown-width;
      left: $desktop-left-offest;
    }
    
    .defaultValue{
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: $userAccountField;
      padding: $no-padding $padding-15;
      width: $selectedValueWidth;
      background-color: $color-background-white-secondary;
      border: $border-size solid $color-border;
      border-radius: $border-radius-round-slight;
    }

    .label {
      display: flex;
      width: $labelWidth;
      font-size: $font-size-slightly-smallText;
      justify-content: start;
      align-items: center;
      margin: $no-margin;
      color: $color-black;
    }

    .dropDownArrow {
      display: none;
    }

    .dropDownArrowMobile {
      display: flex;
      align-items: center;
      position: absolute;
      top:0;
      right: $padding-35;
      height: $full;
    }
  }
}

// Mobile
@media (max-width: $tablet-max-breakpoint) {
  .userSettings {
    display: flex;
    align-items: end;
    justify-content: start;
    .dropDownArrowMobile {
      display: none;
    }
  }

  .tableQuestionMobileStyle {
    .dropDownContainer {
      border: none;
      padding: $no-padding;

      &:hover {
        background-color: inherit; 
        color: inherit; 
        border: none;
      }
    }
  }
}
