@import "scss/tables.scss";
@import "scss/base.scss";

$button-min-height: 48px;
$cell-height: 160px;
$first-cell-width: 35%;
$second-cell-width: 40%;
$client-table-min-height: 250px;
$questionnaire-modal-height: 80%;
$questionnaire-modal-max-width: 1000px;
$questionnaire-modal-mobile-width: 90%;
$questionnaire-modal-mobile-height: 90%;
$modal-margin-side: 25%;
$modal-row-height: 60px;
$document-modal-min-height: 30%;
$document-modal-height: 80%;
$document-modal-left: 10%;
$save-button-max-width: 500px;
$save-button-width: 35%;
$modal-height: 20%;

.applicationInfo {
    margin-top: $margin-10;

    h2 {
        @include pageHeader();
        margin-top: $margin-medium;
    }
    @include pageHeader();
}

.clientTableContainerWrapper {
    @include pageContentWrapper();
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
    box-shadow: none;
}

.clientTableContainer {
    @include scrollableContent();
    flex: 1;
    overflow: auto;
}

.filterButtons {
    @include filterButtons();
    margin: 0.75rem;
    padding-bottom: $padding-10;
}

.section {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
    margin-left: $margin-30;
    margin-right: $margin-30;
}

.clientTable {
    width: $full;
    border-collapse: collapse;
    font-size: $font-size-slightly-large;

    td {
        max-width: 0;
    }
}

.documentContainerWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
}

.tabsContainerWrapper {
    @include pageContentWrapper();
    display: flex;
    flex-direction: column;
    height: calc(100vh - 300px);
    background-color: #ffffff;
    opacity: 1;
}

.tabs {
    display: flex;
    width: $full;
    position: relative;
    padding-bottom: 1px;
    justify-content: center;
    gap: 20px;
}

.tabs::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 0;
    background-color: $color-grey;
    z-index: 1;
}

.tab {
    min-height: 32px;
    border-radius: $border-radius-round-slight;
    cursor: pointer;
    font-size: $font-size-slightly-smallText;
    appearance: $hide;
    align-items: center;
    line-height: $line-height-small;
    width: 48%;
    max-width: none;
    padding: 0 15px;
    font-weight: 550;
    background-color: $color-background;
    border-color: $color-button-background;
    color: $color-input-grey;
    border: $hide;
    position: relative;
    margin-bottom: 4px;

    &:disabled {
        cursor: not-allowed;
    }
    div, p, a, span {
        color: $color-button-text;
    }

    &:not(.activeTab):hover {
        background-color: $color-background-tab;
    }

    &:disabled {
        background-color: rgba($color-button-background, 0.5);
        border-color: rgba($color-button-background, 0.5);

        &:hover {
            background-color: rgba($color-white, 0.25);
            border-color: rgba($color-white, 0.25);
        }
    }

    .notificationBadge {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: $color-form-error;
        color: white;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        padding: 0;
        font-size: 12px;
        margin-right: 8px;
        font-weight: bold;
        order: -1;
    }
}

.tabHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.activeTab {
    position: relative;
    background-color: $color-background;
    color: $color-submit-button-background;
}

.activeTab::after {
    content: "";
    display: block;
    width: 100%;
    height: 3px;
    position: absolute;
    bottom: -4px;
    left: 50%;
    background-color: $color-blue;
    transform: translateX(-50%);
    z-index: 2;
}

.uploadDocumentModal {
    height: $modal-height;
    margin: 0 $modal-margin-side;
    margin-bottom: $margin-60;
    padding: $padding-20;

    .modalHeader {
        margin: $no-margin;
        justify-content: center;
        text-align: center;
    }

    .modalContent {
        height: $full;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow-y: auto;
        
        .contentRow {
            width: $full;
            height: $modal-row-height;
            border-bottom: $border-size solid $color-grey;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.infoRow {
    display: flex;
    flex-direction: row;
    margin-bottom: $margin-medium;
}

.questionnairesTable {
    border-collapse: separate;
    border-spacing: 0 $spacing-small;
    
    td:nth-child(2) {
        width: 40%;
        vertical-align: top;
        padding-top: 12px;
    }
}

.documentModal {
    width: $modal-width;
    height: $document-modal-height;
    min-height: $document-modal-min-height;
    margin-left: $document-modal-left;
    margin-top: $margin-50;
    display: flex;
    flex-direction: column;

    h2 {
        font-size: $font-size-slightly-large;
    }

    img {
        width: $full;
        height: $full;
    }

    .modalContent {
        margin-left: $margin-10;
        height: 90%;
        overflow: auto;
        overflow-wrap: break-word;
        text-align: center;
        
        .contentRow {
            width: $full;
            border-bottom: $border-size solid $color-grey;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
        /* Container for file viewers */
        .file-viewer-container {
            display: inline-block;
            min-width: 80%;
            max-width: 100%;
        }
    }

    .modalFooter {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: $margin-10;
        
        .downloadButton {
            width: $save-button-width;
            max-width: $save-button-max-width;
            margin: $no-margin;
            background-color: inherit;
        }
    }
}

.information {
    min-height: $cell-height;
    border-radius: $border-radius-round-slight;
    margin-right: $margin-10;
    background: linear-gradient($color-background, $color-background) padding-box,
                var(--blue-yellow-gradient) border-box;
    border-radius: $border-radius-round-slight;
    border: $border-size-slightly-big solid transparent;
    flex-basis: 50%;
    flex-grow: 0;
    padding: $padding-10 $padding-30 $no-padding $padding-30;
}

.headerName {
    font-size: $font-size-large;
    font-weight: $font-weight-bolder;
    margin-bottom: $margin-5;
}

.clientHeaderName {
    font-size: $font-size-large;
    font-weight: $font-weight-bolder;
    margin-bottom: $margin-15;
}

.subName {
    font-size: $font-size-smallerText;
    color: $color-text-subname;
}

.answer {
    margin-bottom: $margin-10;
}

.answerPhone {
    margin-bottom: $margin-20;
}

.lawyerInfo {
    min-height: $cell-height;
    position: relative;
    border-radius: $border-radius-round-slight;
    margin-right: $margin-10;
    background: linear-gradient($color-background, $color-background) padding-box,
                var(--blue-yellow-gradient) border-box;
    border-radius: $border-radius-round-slight;
    border: $border-size-slightly-big solid transparent;
    flex-basis: 25%;
    flex-grow: 0;
    padding: $padding-10 $padding-15 $no-padding $padding-15;
}

.analyst {
    min-height: $cell-height;
    position: relative;
    border-radius: $border-radius-round-slight;
    background: linear-gradient($color-background, $color-background) padding-box,
                var(--blue-yellow-gradient) border-box;
    border-radius: $border-radius-round-slight;
    border: $border-size-slightly-big solid transparent;
    flex-basis: 25%;
    flex-grow: 0;
    padding: $padding-10 $padding-15 $no-padding $padding-15;
}

.questionnaireModal {
    width: $modal-width;
    max-width: $questionnaire-modal-max-width;
    height: $questionnaire-modal-height;
    position: fixed;
    top: $half;
    left: $half;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
}

.documentsTable {
    border-collapse: separate;
    border-spacing: 0 8px;
    
    thead tr {
        th {
            padding: $padding-15 $padding-20;
            border-bottom: 1px solid $color-grey;
        }
    }
    
    tbody tr {
        position: relative;
        background-color: $color-background;
        border-radius: $border-radius-round-slight $border-radius-round-slight 0 0;
        cursor: pointer;
        
        td {
            padding: $padding-15 $padding-20;
            position: relative;
        }
        
        &:not(:last-child)::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: -4px;
            height: 1px;
            background-color: $color-grey;
            z-index: 2;
        }
        
        &:hover {
            background-color: rgba($color-background-hover, 0.1);
        }
    }
    
    th, td {
        &:nth-child(1) { // Document Name
            width: 50%;
        }
        &:nth-child(2) { // Uploaded By
            width: calc(50% / 3);
        }
        &:nth-child(3) { // Uploaded On
            width: calc(50% / 3);
        }
        &:nth-child(4) { // Download button
            width: calc(50% / 3);
        }
    }
}

.tableScrollContainer {
    height: calc(100vh - 400px);
    overflow-y: auto;
    position: relative;
}

/* Media Queries */
// Desktop
@media only screen and (min-width: $tablet-max-breakpoint) {
    .clientTable:not(.documentsTable) {
        td:first-child {
            width: $first-cell-width;
        }
        
        td:nth-of-type(2) {
            width: $second-cell-width;
            color: $color-progress-bar-grey;
            opacity: $color-progress-bar-opacity;
        }
    }
}

// Mobile
@media (max-width: $tablet-max-breakpoint) {
    .lawyerInfo,
    .analyst {
        display: $hide;
    }

    .information {
        min-height: $cell-height;
        margin-right: $no-margin;
        flex-basis: 100%;
        padding: $padding-10 $padding-20 $padding-5 $padding-20;

        .headerName {
            margin-bottom: $margin-10;
        }

        .answer {
            margin-bottom: $margin-15;
            font-size: $font-size-slightly-smallText;
        }

        .mobileContactRow {
            display: flex;
            gap: $margin-10;
            
            > div {
                flex: 1;
            }
        }
    }
    .filterButtons {
        flex-wrap: wrap;
        row-gap: $margin-10;
        margin: 0.75rem 0;
        .newAppButton, .filter, .sort {
            flex: 1;
        }
        .searchBar {
            width: $full;
        }
    }

    .newApplication {
        width: $questionnaire-modal-mobile-width;
        height: $questionnaire-modal-mobile-height;
    }

    .applicationInfo {
        display: $hide;
    }

    .dropDownDirectionClass {
        right: 0;
    }

    .section {
        margin-left: $no-margin;
        margin-right: $no-margin;
    }

    .tab {
        margin: 0 5px;
        padding: 0 10px;
        width: 47%;
        min-width: unset;
    }

    .documentsTable {
        th, td {
            &:nth-child(2),
            &:nth-child(3) {
                display: none;
            }
            
            &:nth-child(1) { // Document Name
                width: 70%;
            }
            &:nth-child(4) { // Download button
                width: 30%;
            }
        }
    }
}


