@mixin dropShadow {
    -moz-box-shadow: 0px 4px 65px -19px rgba(42, 49, 146, 0.28);
    -webkit-box-shadow: 0px 4px 65px -19px rgba(42, 49, 146, 0.28);
    box-shadow: 0px 4px 65px -19px rgba(42, 49, 146, 0.28);
}

@mixin pageContentWrapper {
    @include dropShadow();
    display: flex;
    flex: 1;
    overflow-y: inherit;
    background-color: $color-background;
    padding: $padding-10 $padding-20 $padding-10 $padding-20;
    border-radius: $border-radius-round-mid;

    @media (max-width: $tablet-max-breakpoint) {
        padding-right: $padding-10;
    }
}

@mixin scrollableContent {
    overflow-y: auto;
    height: $full;
    width: $full;
    padding-right: $padding-20;

    @media (max-width: $tablet-max-breakpoint) {
        padding-right: $padding-10;
    }
}

@mixin pageHeader {
    display: flex;
    align-items: center;
    margin-bottom: $margin-25;

    .backArrow {
        margin-right: $margin-medium;
    }

    h2 {
        margin: $no-margin;
    }
}

@mixin filterButtons {
    margin-bottom: $margin-25;
    display: flex;
    column-gap: $margin-10;
    align-items: center;
}

@mixin formContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: $full;
    width: $full;
}

  // TODO: Since we're using overrides, we should make sure
  // we're not using shortened css fields in the mixin
  // like border should be broken down into 
  // border-size, border-color, border-style
@mixin formInputStyle {
    box-sizing: border-box;
    border: $border-size solid $color-input-border;
    width: $full;
    border-radius: $border-radius-round-slight;
    background-color: transparent;
    min-height: $input-min-height;

    color: $color-black;

    .label {
        font-size: $font-size-smallerText;
        color: $color-input-dark;
        text-overflow: ellipsis;
    }
    
    input, textarea {
        &:disabled {
            color: $color-black;
            pointer-events: none;
        }
    }
    select {
        &:disabled {
            opacity: $full;
        }
    }
    ::placeholder {
        color: $color-input-placeholder;
        font-style: normal;
    }
}