@import 'scss/base.scss';

$view-document-min-height: 30px;



.viewDocument {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius-round;
    min-height: $view-document-min-height;
    font-size: $font-size-smallText;
    margin: $no-margin $margin-small $no-margin $no-margin;
    padding: $no-padding $padding-10;
}

.buttonTitle {
    margin: $no-margin $margin-10;
}


/* Media Queries */
// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {
    .headers {
        display: $hide;
    }
    .textTitle{
        text-align: left;
    }
    
}
