@import "scss/base.scss";

.container {
    display: $hide;
    width: $full;
    height: $full;
    background-color: $color-background-white-secondary;
}

.container svg {
    margin: auto;
}

/* Media Queries */
// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) {
    .container {
        display: flex;
    }
}