@import "scss/base.scss";

$dropdown-row-height: 35px;
$filter-icon-width: 24px;

.filterContainer{
    position: relative;

}

.filter {
    border-width: $border-size;
    border-style: solid;
    border-radius: $border-radius-round;
    border-color: $color-grey;
    padding-left: $padding-15;
    padding-right: $padding-10;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: $font-weight-normal;
    background-color: $color-input-white;
    font-size: $font-size-slightly-smallText;
    white-space: nowrap;
    cursor: pointer;
    text-align: center;
    line-height: $line-height-small;
    height: $input-min-height-small;

    &:hover {
        background-color: $color-background-hover;
    }

    .filterIcon, .sortIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: $padding-10;
        width: $filter-icon-width;
    }
}

.filterDropdown {
    @include dropShadow();
    position: absolute;
    z-index: 5;
    display: flex;
    flex-direction: column;
    background-color: $color-background;
    border-radius: $border-radius-round-slight;
    padding: $padding-small;

    .filterRow {
        display: flex;
        height: $dropdown-row-height;
        align-items: center;
        padding-left: $padding-xsmall;
        padding-right: $padding-small;
        margin: $margin-5 $no-margin;
        font-size: $font-size-slightly-smallText;
        cursor: pointer;

        input {
            margin-right: $margin-small;
        }


        &:hover {
            background-color: $color-background-hover;
            border-radius: $border-radius-round;
        }
    }
}

.mobileOnly {
    display: $hide;
}

/* Media Queries */
// Mobile + Tablet
@media only screen and (max-width: $tablet-min-breakpoint) {
    .mobileOnly {
        display: block;
    }
    .desktopOnly {
        display: $hide;
    }
}

// // Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {
}