@import "scss/base.scss";
@import "scss/tables.scss";

$title-number-width: 40px;
$dropdown-content-max-height: 48px;
$submit-button-width: 30%;

$questionnaire-modal-height: 80%;
$questionnaire-modal-max-width: 1000px;

.editTableModal {
    width: $modal-width;
    max-width: $questionnaire-modal-max-width;
    height: $questionnaire-modal-height;
    position: fixed;
    top: $half;
    left: $half;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
}

.section {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: $no-margin;
    margin-left: $margin-10;
    margin-right: $margin-20;
    justify-content: space-between;
}

.questionsContainer {
    overflow-y: auto; 
    margin-bottom: $margin-5; 
    flex-grow: 1;
    padding-right: $padding-10;
}


.submitButton {
    width: $submit-button-width;
    height: fit-content;
}

.line {
    border-bottom: $border-bottom-width solid $color-submit-button-background;
    width: $full;
    margin-bottom: $margin-15;
}

.submitButton {
    width: $submit-button-width;
    height: fit-content;
}

.submitError{
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-form-error;    
}


/* Media Queries */
// Mobile + Tablet
@media only screen and (max-width: $tablet-max-breakpoint) {
    .buttonDivDesktop {
        display: none;
    }
    .buttonDivMobile {
        display: flex;
        justify-content: center;
        width: $full; 
        margin-bottom: $margin-20;
    }
  }
  
  // Desktop
  @media only screen and (min-width: $desktop-min-breakpoint) {
    .buttonDivDesktop {
        display: flex;
        justify-content: center;
        width: $full; 
        margin-bottom: $margin-20;
    }
    .buttonDivMobile {
        display: none;
    }
  }
